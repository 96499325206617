const lang = {
edit: "Editar",
save: "Guardar",
cancel: "Cancelar",
delete: "Eliminar",
create: "Crear",
update: "Actualizar",
search: "Buscar",
select: "Seleccionar",
view: "Ver",
submit: "Enviar",
add: "Agregar",
remove: "Eliminar",
close: "Cerrar",
next: "Siguiente",
previous: "Anterior",
filter: "Filtrar",
sort: "Ordenar",
clear: "Limpiar",
manage: "Gestionar",
import: "Importar",
export: "Exportar",
download: "Descargar",
upload: "Subir",
print: "Imprimir",
send: "Enviar",
receive: "Recibir",
approve: "Aprobar",
reject: "Rechazar",
complete: "Completar",
confirm: "Confirmar",
error: "Error",
information: "Información",
status: "Estado",
settings: "Configuración",
preferences: "Preferencias",
notification: "Notificación",
navigation: "Navegación",
home: "Inicio",
dashboard: "Panel de control",
profile: "Perfil",
user: "Usuario",
account: "Cuenta",
supplier: "Proveedor",
product: "Producto",
order: "Pedido",
payment: "Pago",
report: "Informe",
analytics: "Análisis",
audit: "Auditoría",
email: "Correo electrónico",
phone: "Teléfono",
address: "Dirección",
city: "Ciudad",
state: "Estado",
country: "País",
task: "Tarea",
schedule: "Programa",
calendar: "Calendario",
logout: "Cerrar sesión",
overview: "Resumen",
details: "Detalles",
notes: "Notas",
help: "Ayuda",
about: "Acerca de",
language: "Idioma",
timezone: "Zona horaria",
date: "Fecha",
time: "Hora",
quantity: "Cantidad",
price: "Precio",
amount: "Monto",
discount: "Descuento",
subtotal: "Subtotal",
total: "Total",
shipped: "Enviado",
delivered: "Entregado",
pending: "Pendiente",
cancelled: "Cancelado",
returned: "Devuelto",
exchange: "Intercambio",
refund: "Reembolso",
login: "Iniciar sesión",
register: "Registrarse",
sale: "Venta",
tax: "Impuesto",
item: "Artículo",
description: "Descripción",
completed: "Completado",
overdue: "Atrasado",
today: "Hoy",
tomorrow: "Mañana",
recurring: "Recurrente",
daily: "Diario",
weekly: "Semanal",
monthly: "Mensual",
yearly: "Anual",
day: "Día",
week: "Semana",
month: "Mes",
year: "Año",
subject: "Asunto",
message: "Mensaje",
sent: "Enviado",
drafts: "Borradores",
enable: "Habilitar",
disable: "Deshabilitar",
on: "Encendido",
off: "Apagado",
loading: "Cargando",
info: "Información",
confirmation: "Confirmación",
yes: "Sí",
no: "No",
hello: "Hola",
name: "Nombre",
invoice: "Factura",
quote: "Albarán",
offer: "Oferta",
lead: "Prospecto",
customer: "Cliente",
admin: "Administrador",
employee: "Empleado",
currency: "Moneda",
invoice_list: "Lista de facturas",
add_new_invoice: "Agregar nueva factura",
record_payment: "Registrar pago",
quote_list: "Lista de albaranes",
add_new_quote: "Agregar nuevo albarán",
offer_list: "Lista de ofertas",
add_new_offer: "Agregar nueva oferta",
lead_list: "Lista de prospectos",
add_new_lead: "Agregar nuevo prospecto",
customer_list: "Lista de clientes",
add_new_customer: "Agregar nuevo cliente",
payment_list: "Lista de pagos",
add_new_payment: "Agregar nuevo pago",
settings_list: "Lista de configuraciones",
add_new_settings: "Agregar nueva configuración",
admin_list: "Lista de administradores",
add_new_admin: "Agregar nuevo administrador",
employee_list: "Lista de empleados",
add_new_employee: "Agregar nuevo empleado",
currency_list: "Lista de monedas",
add_new_currency: "Agregar nueva moneda",
payment_mode: "Modo de pago",
payment_mode_list: "Lista de modos de pago",
add_new_payment_mode: "Agregar nuevo modo de pago",
email_template: "Plantilla de correo electrónico",
email_template_list: "Lista de plantillas de correo electrónico",
add_new_email_template: "Agregar nueva plantilla de correo electrónico",
product_list: "Lista de productos",
add_new_product: "Agregar nuevo producto",
order_list: "Lista de pedidos",
add_new_order: "Agregar nuevo pedido",
general_settings: "Configuración general",
email_templates: "Plantillas de correo electrónico",
birthday: "Cumpleaños",
birthplace: "Lugar de nacimiento",
department: "Departamento",
gender: "Género",
position: "Posición",
accepted: "Aceptado",
add_field: "Agregar campo",
advanced_settings: "Configuraciones avanzadas",
after: "Después",
allowed_role: "Rol permitido",
app_name: "Nombre de la aplicación",
app_settings: "Configuración de la aplicación",
available_variables: "Variables disponibles",
back: "Atrás",
balance: "Saldo",
before: "Antes",
body: "Cuerpo",
cent_precision: "Precisión en centavos",
client: "Cliente",
company: "Empresa",
company_settings: "Configuración de la empresa",
convert_to_invoice: "Convertir a factura",
crm_settings: "Configuración de CRM",
currency_format: "Formato de moneda",
currency_name: "Nombre de moneda",
currency_position: "Posición de moneda",
currency_symbol: "Símbolo de moneda",
decimal_separator: "Separador decimal",
default_mode: "Modo predeterminado",
delete_confirmation: "Confirmación de eliminación",
download_pdf: "Descargar PDF",
draft: "Borrador",
email_content: "Contenido del correo electrónico",
enabled: "Habilitado",
expire_date: "Fecha de vencimiento",
finance_settings: "Configuración financiera",
first_name: "Primer nombre",
interested: "Interesado",
last_name: "Apellido",
manager_first_name: "Nombre del gerente",
manager_last_name: "Apellido del gerente",
money_format_settings: "Configuración del formato de dinero",
new: "Nuevo",
not_interested: "No interesado",
note: "Nota",
number: "Número",
password: "Contraseña",
payment_information: "Información de pago",
photo: "Foto",
position_in_company: "Posición en la empresa",
preview: "Resumen",
profile_settings: "Configuración de perfil",
reached: "Alcanzado",
recent_invoices: "Facturas recientes",
recent_quotes: "Albaranes recientes",
reference: "Referencia",
refresh: "Actualizar",
role: "Rol",
select_language: "Seleccionar idioma",
send_by_email: "Enviar por correo electrónico",
setting: "Configuración",
setting_category: "Categoría de configuración",
setting_name: "Nombre de configuración",
show: "Mostrar",
show_invoice: "Mostrar factura",
sorry_the_page_you_requested_does_not_exist: "Lo sentimos, la página que solicitó no existe",
source: "Origen",
tax_total: "Total de impuestos",
thousand_separator: "Separador de miles",
to_write_a_variable_name_use_the_convention: "Para escribir un nombre de variable, utilice la convención",
total_paid: "Total pagado",
total_remaining: "Total restante",
type: "Tipo",
update_currency_format: "Actualizar formato de moneda",
update_password: "Actualizar contraseña",
update_your_company_informations: "Actualizar la información de su empresa",
zero_format: "Formato cero",
no_results_found: "No se encontraron resultados",
please_wait: "Espere por favor",
are_you_sure: "¿Estás seguro?",
confirm_password: "Confirmar contraseña",
current_password: "Contraseña actual",
new_password: "Nueva contraseña",
remember_me: "Recuérdame",
thank_you_exclamation: "¡Gracias!",
welcome_back: "¡Bienvenido de nuevo!",
payment_status: "Estado de pago",
error_404: "Error 404 - Página no encontrada",
error_500: "Error 500 - Error interno del servidor",
sub_total: "Subtotal",
are_you_sure_you_want_to_delete: "¿Estás seguro de que deseas eliminar: ",
core_setting: "Configuración principal",
created: "Creado",
created_by: "Creado por",
credit: "Crédito",
currency_settings: "Configuración de moneda",
declined: "Rechazado",
default: "Predeterminado",
due_balance: "Saldo pendiente",
expired: "Vencido",
expired_date: "Fecha de vencimiento",
invoices_preview: "Resumen de facturas",
not_paid: "No pagado",
offers_preview: "Resumen de ofertas",
paid: "Pagado",
partially: "Parcialmente",
payments_preview: "Resumen de pagos",
quotes_preview: "Resumen de albaranes",
template: "Plantilla",
this_month: "Este mes",
unpaid: "No pagado",
value: "Valor",
active_customer: "Clientes activos",
customer_preview: "Venta clientes",
new_customer_this_month: "Nuevos clientes este mes",
already_have_account_login: "¿Ya tienes una cuenta? Inicia sesión",
forgot_password: "¿Olvidaste tu contraseña?",
log_in: "Iniciar sesión",
or: "O",
sign_in: "Iniciar sesión",
sign_up: "Registrarse",
select_date: "Seleccionar Fecha",
men: "Hombres",
women: "Mujeres",
click_to_upload: "Haga clic para cargar",
admin_super_admin: "Administrador (Super Administrador)",
staff_admin_crud: "Personal Administrador (Crear, Leer, Actualizar, Eliminar)",
staff_cru: "Personal (Crear, Leer, Actualizar)",
create_and_read_only: "Crear y solo lectura",
read_only: "Solo lectura",
register_now: "Regístrate ahora",
forget_password: "Olvidé mi contraseña",
request_new_password: "Solicitar nueva contraseña",
manage_your_company_with: "Administra tu empresa con",
all_in_one_tool: "Herramienta todo en uno",
easily_add_and_manage_your_services: "Agrega y administra fácilmente tus servicios",
it_brings_together_your_invoice_clients_and_leads: "Reúne a tus clientes de facturas y clientes potenciales",
run_and_scale_your_erp_crm_apps: "Ejecuta y escala tus aplicaciones ERP y CRM",
people: "Personas",
customize_this_application: "Personaliza esta aplicación",
do_you_need_help_on_customize_of_this_app: "¿Necesitas ayuda para personalizar esta aplicación?",
contact_us: "Contáctanos",
company_logo: "Logo de la empresa",
application_name: "Nombre de la aplicación",
allow_registration: "Permitir registro",
application_email: "Correo electrónico de la aplicación",
application_url: "URL de la aplicación",
mutli_branch: "Multi sucursal",
company_name: "Nombre",
company_address: "Dirección",
company_state: "Estado de la empresa",
company_country: "País de la empresa",
company_email: "Email de la empresa",
company_phone: "Tlf. de la empresa",
company_cell: "Fijo de la empresa",
company_website: "Web de la empresa",
company_tax_number: "Número de impuestos",
company_vat_number: "Número de IVA",
company_reg_number: "Número de registro",
update_company_logo: "Actualizar logo de la empresa",
update_company_finance_settings: "Actualizar configuración financiera de la empresa",
last_invoice_number: "Último nº de factura",
last_quote_number: "Último nº de albarán",
last_offer_number: "Último nº de oferta",
last_payment_number: "Último nº de pago",
invoice_prefix: "Prefijo de factura",
quote_prefix: "Prefijo de albarán",
offer_prefix: "Prefijo de oferta",
payment_prefix: "Prefijo de pago",
current_invoice_year: "Año actual de factura",
current_quote_year: "Año actual de albarán",
current_offer_year: "Año actual de oferta",
algeria: "Argelia",
company_list: "Lista de empresas",
add_new_company: "Agregar nueva empresa",
contact: "Contacto",
website: "Sitio web",
france: "Francia",
afghanistan: "Afganistán",
albania: "Albania",
andorra: "Andorra",
angola: "Angola",
anguilla: "Anguila",
argentina: "Argentina",
armenia: "Armenia",
aruba: "Aruba",
australia: "Australia",
austria: "Austria",
azerbaijan: "Azerbaiyán",
bahamas: "Bahamas",
bahrain: "Baréin",
bangladesh: "Bangladesh",
barbados: "Barbados",
belarus: "Bielorrusia",
belgium: "Bélgica",
belize: "Belice",
benin: "Benín",
bermuda: "Bermudas",
bhutan: "Bután",
bolivia: "Bolivia",
bosnia_and_herzegovina: "Bosnia y Herzegovina",
botswana: "Botsuana",
brazil: "Brasil",
brunei_darussalam: "Brunéi",
bulgaria: "Bulgaria",
burkina_faso: "Burkina Faso",
burundi: "Burundi",
cabo_verde: "Cabo Verde",
cambodia: "Camboya",
cameroon: "Camerún",
canada: "Canadá",
central_african_republic: "República Centroafricana",
chad: "Chad",
chile: "Chile",
china: "China",
colombia: "Colombia",
comoros: "Comoras",
congo: "Congo",
costa_rica: "Costa Rica",
croatia: "Croacia",
cuba: "Cuba",
cyprus: "Chipre",
czechia: "Chequia",
cote_d_ivoire: "Costa de Marfil",
denmark: "Dinamarca",
djibouti: "Yibuti",
dominica: "Dominica",
dominican_republic: "República Dominicana",
ecuador: "Ecuador",
egypt: "Egipto",
el_salvador: "El Salvador",
eritrea: "Eritrea",
estonia: "Estonia",
eswatini: "Esuatini",
ethiopia: "Etiopía",
fiji: "Fiyi",
finland: "Finlandia",
gabon: "Gabón",
gambia: "Gambia",
georgia: "Georgia",
germany: "Alemania",
ghana: "Ghana",
gibraltar: "Gibraltar",
greece: "Grecia",
greenland: "Groenlandia",
guadeloupe: "Guadalupe",
guam: "Guam",
guatemala: "Guatemala",
guernsey: "Guernsey",
guinea: "Guinea",
guinea_bissau: "Guinea-Bisáu",
guyana: "Guyana",
haiti: "Haití",
honduras: "Honduras",
hong_kong: "Hong Kong",
hungary: "Hungría",
iceland: "Islandia",
india: "India",
indonesia: "Indonesia",
iran: "Irán",
iraq: "Irak",
ireland: "Irlanda",
israel: "Israel",
italy: "Italia",
jamaica: "Jamaica",
japan: "Japón",
jordan: "Jordania",
kazakhstan: "Kazajistán",
kenya: "Kenia",
korea: "Corea",
kuwait: "Kuwait",
kyrgyzstan: "Kirguistán",
latvia: "Letonia",
lebanon: "Líbano",
lesotho: "Lesoto",
liberia: "Liberia",
libya: "Libia",
liechtenstein: "Liechtenstein",
lithuania: "Lituania",
luxembourg: "Luxemburgo",
madagascar: "Madagascar",
malawi: "Malaui",
malaysia: "Malasia",
maldives: "Maldivas",
mali: "Malí",
malta: "Malta",
martinique: "Martinica",
mauritania: "Mauritania",
mauritius: "Mauricio",
mexico: "México",
moldova: "Moldavia",
monaco: "Mónaco",
mongolia: "Mongolia",
montenegro: "Montenegro",
montserrat: "Montserrat",
morocco: "Marruecos",
mozambique: "Mozambique",
myanmar: "Myanmar (Birmania)",
namibia: "Namibia",
nepal: "Nepal",
netherlands: "Países Bajos",
new_caledonia: "Nueva Caledonia",
new_zealand: "Nueva Zelanda",
nicaragua: "Nicaragua",
niger: "Níger",
nigeria: "Nigeria",
norway: "Noruega",
oman: "Omán",
pakistan: "Pakistán",
palestine: "Palestina",
panama: "Panamá",
papua_new_guinea: "Papúa Nueva Guinea",
paraguay: "Paraguay",
peru: "Perú",
philippines: "Filipinas",
poland: "Polonia",
portugal: "Portugal",
puerto_rico: "Puerto Rico",
qatar: "Catar",
macedonia: "Macedonia del Norte",
romania: "Rumania",
russia: "Rusia",
rwanda: "Ruanda",
r_union: "Reunión",
saudi_arabia: "Arabia Saudita",
senegal: "Senegal",
serbia: "Serbia",
singapore: "Singapur",
slovakia: "Eslovaquia",
slovenia: "Eslovenia",
somalia: "Somalia",
south_africa: "Sudáfrica",
south_sudan: "Sudán del Sur",
spain: "España",
sri_lanka: "Sri Lanka",
sudan: "Sudán",
suriname: "Surinam",
sweden: "Suecia",
switzerland: "Suiza",
syria: "Siria",
taiwan: "Taiwán",
tajikistan: "Tayikistán",
tanzania: "Tanzania",
thailand: "Tailandia",
timor_leste: "Timor Oriental",
togo: "Togo",
tonga: "Tonga",
tunisia: "Túnez",
turkey: "Turquía",
turkmenistan: "Turkmenistán",
uganda: "Uganda",
ukraine: "Ucrania",
united_arab_emirates: "Emiratos Árabes Unidos",
united_kingdom: "Reino Unido",
united_states: "Estados Unidos",
uruguay: "Uruguay",
uzbekistan: "Uzbekistán",
venezuela: "Venezuela",
vietnam: "Vietnam",
yemen: "Yemen",
zambia: "Zambia",
zimbabwe: "Zimbabue",
person: "Persona",
people_list: "Lista de personas",
add_new_person: "Agregar nueva persona",
firstname: "Nombre",
lastname: "Apellido",
client_list: "Lista de clientes",
add_new_client: "Agregar nuevo cliente",
idurar_app_title: "ERP CRM de código abierto Facturación Contabilidad Gastos",
verify_your_account: "Verifica tu cuenta",
complete_verification_by_providing_the_code_that_you_received_by_email: "Completa la verificación proporcionando el código que recibiste por correo electrónico",
verify_now: "Verificar ahora",
expense: "Gasto",
product_category: "Familias Producto",
expenses_category: "Categoría de Gastos",
taxes: "Impuesto",
product_category_list: "Lista de Categorías de Producto",
add_new_product_category: "Agregar Nueva Categoría de Producto",
color: "Color",
expense_list: "Lista de Gastos",
add_new_expense: "Agregar Nuevo Gasto",
expense_category: "Categoría de Gasto",
ref: "Referencia",
expense_category_list: "Lista de Categorías de Gasto",
add_new_expense_category: "Agregar Nueva Categoría de Gasto",
taxes_list: "Lista de Impuestos",
add_new_tax: "Agregar Nuevo Impuesto",
date_format: "Formato de fecha",
server_url: "URL del servidor",
}
 export default lang